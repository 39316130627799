.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 20px auto;
    width: 40%;
  }
  
  @media (max-width: 768px) {
    .contentContainer {
      width: 95%;
    }
  }

  .contentContainer img {
    max-width: 100%;
    height: auto;
  }