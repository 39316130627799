.searchContainer {
    display: flex;
    justify-content: center;
    margin-right: 10px;
}

.search input[type="text"] {
    width: 200px;
    padding: 10px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
  
.searchButton {
    background-color: #00703c;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: .5s ease;
    font-weight: bold;
}
  
.searchButton:hover {
    color: rgb(255, 255, 255);
    background-color: 	#026135 ;
}
