.loaderContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:30px;    
  }
  
  .loader {
    width: 70px;
    height: 70px;
    position: relative;
  }
  
  .loader:after {
    content: "";
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top-color:#003078;
    position: absolute;
    top: 0;
    left: 0;
    animation: spin 2s linear infinite;
  }
  
  .loaderText {
    font-size: 24px;
    margin-top: 20px;
    color:#003078;
    text-align: center;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0.6);
      opacity: 1;
    }
    50% {
      transform: scale(1.2);
      opacity: 0;
    }
    100% {
      transform: scale(0.6);
      opacity: 1;
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
