.accordion {
    border-radius: 4px;
    margin: 10px 0;
    width: 100%;
  }
  
.accordionHeader {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.accordionIcon{
    font-size: 24px;
    color:	#5694ca;
}