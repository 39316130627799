.footer {
    padding: 20px;
    background-color:  	#f3f2f1;
    border-top: 1px solid  	#b1b4b6;
  }
  
  .footerLinks {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .footerLinkItem {
    margin: 0 10px;
  }
  
  .footerLink {
    text-decoration: none;
    color: #000;
  }
  
  .footerLink:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 600px) {
    .footerLinks {
      flex-direction: column;
      align-items: center;
    }
  
    .footerLinkItem {
      margin: 5px 0;
    }
  }