.errorMessageOverlay {
    position: absolute;
    background-color:  	#d4351c;
    color: white;
    text-align: left;
    font-weight: bold;
    padding: 10px;
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
    border: 2px solid black;
  }

.leftHandPanelButton {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.container {
  display: flex;
  position: relative;
}

.leftPanel {
  padding: 0px;
  border-right: 1px solid #ccc;
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  position: relative;
}

.leftHandPanelOpen{
  width: 350px;
}

.leftHandPanelClosed{
  width: 20px;
}

.flexContainer {
  flex: 1;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.mapContainer {
  width: 100%;
  height: 80svh;
}

.resultContainer {
  position: fixed;
  background-color: white;
  z-index: 1000;
  max-height: 350px;
  overflow-y: auto;
  width: 250px;
}

.resultHeader {
  margin: 0 0 10px 0;
}

.resultList {
  list-style-type: none;
  padding: 0;
}

.resultItem {
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.resultsXY {
  font-size: smaller;
}

@media (max-width: 768px) {
  .leftHandPanelOpen{
    width: 100%;
  }

  .leftHandPanelClosed{
    width: 20px;
  }

  .flexContainer{
    width: 1px;
  }
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loadingBar {
  width: 50%;
  height: 10px;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.loadingBar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #003078;
  animation: loading 2s infinite;
}

@keyframes loading {
  0% { width: 0; }
  100% { width: 100%; }
}
