.legend-container{
    max-height: 650px;
    overflow-y: auto;
    margin-right: 20px;
}

.legend-item-container{
    border-bottom: 1px solid #0b0c0c;
}

.legend-item{
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 0 10px;
}

.legend-color{
    border-radius: 20%;
    border: 2px solid black;
}

.legend-info{
    width: 85%;
    padding: 5px;
    border: 3px solid  	#1d70b8;
    margin: 10px;
}

.controlPanelHeader{
    display: flex;
    background-color:  rgb(42, 39, 39);
    color: white;
}

.headerTitle{
    margin: 0;
    padding: 10px;
}

input[type="checkbox"] {
    height: 1.7rem;
    min-width: 1.7rem;
    margin: 5px;
    display: inline-block;
    appearance: none;
    position: relative;
    background-color:  	#f3f2f1;
    border-radius: 15%;
    cursor: pointer;
    overflow: hidden;
   }
   
   input[type="checkbox"]::after {
    content: '';
    display: block;
    height: 0.9rem;
    min-width: .4rem;
    border-bottom: .31rem solid #a0ffe7;
    border-right: .31rem solid #a0ffe7;
    opacity: 0;
    transform: rotate(45deg) translate(-50%, -50%);
    position: absolute;
    top: 45%;
    left: 21%;
    transition: .25s ease;
   }
   
   input[type="checkbox"]::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    background-color: #00C896;
    border-radius: 50%;
    opacity: .5;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    transition: .3s ease;
   }
   
   input[type="checkbox"]:checked::before {
    height: 130%;
    width: 130%;
    opacity: 100%;
   }
   
   input[type="checkbox"]:checked::after {
    opacity: 100%;
   }