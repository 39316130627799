.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #181b1b;
    color: white;
    border-bottom: 1px solid #505a5f;
}

.navbarLinks {
    list-style: none;
    display: flex;
    gap: 1rem;
    font-weight: bold;
}

.navbarLinks li {
    display: inline;
}

.navbarLinks a {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: background-color 0.4s;
    border-radius: 5px;
}

.navbarLinks a:hover {
    background-color: #2ab759;
    border-radius: 5px;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.burgerIcon{
    color: white;
    font-size: 25px;
}

.hamburger div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px;
    transition: all 0.3s;
}

@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
    }

    .navbarLinks {
        display: none;
        flex-direction: column;
        gap: 0.5rem;
    }

    .navbarLink{
        margin: 5px
    }

    .navbarLinks.active {
        display: flex;
    }

    .hamburger {
        margin-top: 10px;
        display: flex;
    }
}

.navbarLogo {
    height: 40px;
    width: auto;
}