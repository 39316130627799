.button {
    width: 100px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    background: #f47738;
}

.button, .button span {
    transition: 200ms;
}

.text {
    color: white;
    font-weight: bold;
}

.icon {
    position: absolute;
    transform: translateX(60px);
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon svg {
    width: 10px;
    fill: #ffffff;
}

.button:hover {
    background: #f78349;
}

.button:hover .text {
    color: transparent;
}

.button:hover .icon {
    width: 100px;
    border-left: none;
    transform: translateX(0);
}

.button:active .icon svg {
    transform: scale(0.8);
}