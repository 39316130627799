.searchFilterContainer {
  display: flex;
  align-items: center;
  justify-content: center;

}
  
@media (max-width: 768px) {
.searchFilterContainer {
    display: block;
}

.datePicker{
    margin: 20px;
}
}

.dashboardTitle {
    display: flex;
    justify-content: center;
}

.filtersContainer {
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 20px;
}

select {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    color: #333;
    width: 100%;
    box-sizing: border-box;
}

option {
    background-color: #fff;
    color: #333;
    padding: 8px;
    font-size: 16px;
}

.datePicker{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px;
    margin-top: 20px;
}

.card {
    display: flex;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 40%;
    padding: 16px;
    max-width: 300px;
    margin: 16px auto;
}

.cardContent {
text-align: center;
}

.cardContent h2 {
margin-bottom: 16px;
}

.cardContent p {
font-size: 18px;
color: #333;
}

.statsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin: 1rem 0;
}

.card {
    background: white;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.chartsContainer {
    background: white;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.noData {
    font-weight: bold;
}

.tooltip {
    position: absolute;
    padding: 8px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 4px;
    pointer-events: none;
    font-size: 12px;
}

.chartsContainer polyline {
    opacity: 0.6;
}

.chartsContainer:hover polyline {
    opacity: 1;
    stroke-width: 2px;
}

.dotDistributionMap {
    display: flex;
    align-items: center;
    justify-content: center;
  
  }