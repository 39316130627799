.title{
  display: flex;
  justify-content: center;
}

.tableContainer {
  display: flex;
  justify-content: center;
  margin: 5px;
}
  
.centeredTable {
  margin: 0 auto;
  border-collapse: collapse;
}

.centeredTable th, .centeredTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.centeredTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f2f2f2;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.paginationPageCount {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin: 20px;
}

.paginationButton {
  background-color: #2ab759;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.paginationButton:hover {
  background-color: #00703c;
}
  
.paginationButton:disabled {
  background-color:  	#b1b4b6;
  cursor: not-allowed;
}

.downloadIcon {
  font-size: 24px;
  color:#00703c;
  margin-right: 5px;
}

.actionBar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
}

.exportButton {
  background-color:#f3f2f1;
  color: #000000;
  cursor: pointer;
  transition: .5s ease;
  height: 30px;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  align-content: space-between;
  text-decoration: none;
  border: 1px solid #000000;
}

.exportButton:hover {
  background-color:  	#e0dfde;
}

@media (max-width: 768px) {
  .actionBar {
      display: block;
  }

  .tableContainer {
      display: block;
  }
  
  .exportButtonContainer{
      display: flex;
      justify-content: center;
      margin: 5px;
  }
}