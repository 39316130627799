.title{
    text-align: center;
}

.contentContainer {
  width: 55%;
  margin: 0 auto;
  }
  
  @media (max-width: 768px) {
    .contentContainer {
      width: 95%;
    }
  }